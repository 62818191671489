<script>
import { FormWizard, TabContent } from "@anivive/vue3-form-wizard";

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
// import CKEditor from "@ckeditor/ckeditor5-vue";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Loader from '@/components/widgets/loader';
import Swal from 'sweetalert2';
import router from '@/router';

import brandGetall from '../../../state/api/brandGetAll';
import getFindByBrandId from '../../../state/api/getFindByBrandId';
//import getFindByCategoryIdList from '../../../state/api/getFindByCategoryIdList';
import LanguageGetAll from "../../../state/api/LanguageGetAll";
import UserTypeGetAll from "../../../state/api/UserTypeGetAll";
import categorySaveOrUpdate from "../../../state/api/categorySaveOrUpdate";

/**
 * Form wizard component
 */
export default {
  page: {
    title: "Form Wizard",
    meta: [{ name: "description", content: appConfig.description }],
  },
  //components: { Layout, PageHeader, FormWizard, TabContent,ckeditor: CKEditor.component,Loader },
  components: { Layout, PageHeader, FormWizard, TabContent,Loader },
  
  data() {
    return {
      title: "Add Category",
      items: [
        {
          text: "Categories",
          href: "/category/category-list",
        },
        {
          text: "Add Category",
          active: true,
        },
      ],
      //editor: ClassicEditor,
      catagoryList : [],
      languageList : [],
      brandList : [],
      UserTypeList : [],
      CategoryTitle:"",
      ParentId:"",
      selectedBrand:"",
      brandParentId:null,
      UserTypeId:"",
      ShortDescription:"",
      LongDescription:"",
      IsHtml:false,
      IsHome:false,
      isFavoriteFlag:false,
      isNewFlag:false,
      OrderBy:"",
      HomePageOrderBy:"",
      LanguageId:"",
      isActive:false
    };
  },
  methods:{
    async brandGetall(){
        const {getBrandGetAll} = brandGetall()
        const response = await getBrandGetAll();
        this.brandList = response.data;             
    },
    // async getCatagoryList(){
    //     const {CategoryIdList} = getFindByCategoryIdList()
    //     const response = await CategoryIdList();
    //     this.catagoryList = response.data;           
    // },
    async LanguageGetAll(){
        const {LanguageGet} = LanguageGetAll()
        const response = await LanguageGet();
        this.languageList = response.data;           
    },
    async UserTypeGetAll(){
        const {userTypeGet} = UserTypeGetAll()
        const response = await userTypeGet();
        this.UserTypeList = response.data;           
    },
    async catFilter(){
        this.catagoryList =[];
        const {catFindByBrandId} = getFindByBrandId()
        const response = await catFindByBrandId(this.selectedBrand,this.brandParentId);
        this.catagoryList = response.data.categoryList; 
    },
    getAddCategory(){
        let update = {
            "Id":null,
            "Name":this.CategoryTitle,
            "BrandId":this.selectedBrand,
            "ParentId":this.ParentId,
            "UserTypeId":this.UserTypeId,
            "ShortDescription":this.ShortDescription,
            "LongDescription":this.LongDescription,
            "IsHtml":this.IsHtml,
            "IsHome":this.IsHome,
            "IsFavoriteFlag":this.isFavoriteFlag,
            "IsNewFlag":this.isNewFlag,
            "OrderBy":this.OrderBy.toString(),
            "HomePageOrderBy":this.HomePageOrderBy.toString(),
            "LanguageId":this.LanguageId,
            "isActive":this.isActive
        }
        async function updateCategory() {
            const {saveOrUpdate} = categorySaveOrUpdate()
            await saveOrUpdate(update).
            then(res=>{
                if(res.isSuccess){
                    Swal.fire({
                        position: "top-end",
                        icon: "success",
                        title: "Your work has been saved",
                        showConfirmButton: false,
                        timer: 1500
                    })
                    router.push({name:'category-list'})
                }
            })
        }
        updateCategory();
    },
  },
  mounted(){
   // this.getCatagoryList();
    this.LanguageGetAll();
    this.UserTypeGetAll();
    this.brandGetall();
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
        <Loader :loading="categoryDetail">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="row">
                            <div class="col-lg-10">
                                <div class="card-body">
                                    <form-wizard color="#556ee6">
                                        <tab-content icon="mdi mdi-account-circle">
                                            <div class="row">
                                                <div class="col-12">                                                    
                                                    <div class="form-group row mb-3">
                                                        <label class="col-md-3 col-form-label" for="LanguageId"
                                                            >{{$t('user.languageSelect')}}</label
                                                        >
                                                        <div class="col-md-9">
                                                            <select class="form-control" v-model="LanguageId">
                                                                <option v-for="language in languageList" :key="language.id" :value="language.id">{{language.name}}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="form-group row mb-3">
                                                        <label class="col-md-3 col-form-label" for="UserTypeId"
                                                            >{{$t('user.UserSelect')}}</label
                                                        >
                                                        <div class="col-md-9">
                                                            <select class="form-control" v-model="UserTypeId">
                                                                <option v-for="user in UserTypeList" :key="user.id" :value="user.id">{{user.name}}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="form-group row mb-3">
                                                        <label class="col-md-3 col-form-label" for="selectedBrand"
                                                            >{{$t('user.BrandSelect')}}</label
                                                        >
                                                        <div class="col-md-9">
                                                            <select class="form-control" v-model="selectedBrand" @change="catFilter">
                                                                <option v-for="brand in brandList" :key="brand.id" :value="brand.id">{{brand.title}}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="form-group row mb-3">
                                                        <label class="col-md-3 col-form-label" for="ParentId"
                                                            >{{$t('user.MainCategory')}}</label
                                                        >
                                                        <div class="col-md-9">
                                                            <select class="form-control" v-model="ParentId">
                                                                <option v-for="cat in catagoryList" :key="cat.id" :value="cat.id">{{cat.name}}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="form-group row mb-3">
                                                        <label class="col-md-3 col-form-label" for="CategoryTitle"
                                                            >{{$t('user.CategoryTitle')}}</label
                                                        >
                                                        <div class="col-md-9">
                                                            <input
                                                            type="text"
                                                            name="CategoryTitle"
                                                            class="form-control"
                                                            v-model="CategoryTitle"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div class="form-group row mb-3">
                                                        <label class="col-md-3 col-form-label" for="shortDescription"
                                                            >{{$t('user.shortDescription')}}</label
                                                        >
                                                        <div class="col-md-9">
                                                            <input
                                                            type="text"
                                                            name="shortDescription"
                                                            class="form-control"
                                                            v-model="ShortDescription"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div class="form-group row mb-3">
                                                        <label class="col-md-3 col-form-label" for="isHtml"
                                                            >{{$t('user.isHtml')}}</label
                                                        >
                                                        <div class="col-md-9">
                                                            <b-form-checkbox v-model="IsHtml" name="isHtml" switch>
                                                            </b-form-checkbox>
                                                        </div>
                                                    </div>
                                                    <div class="form-group row mb-3">
                                                        <label class="col-md-3 col-form-label" for="LongDescription"
                                                            >{{$t('user.longDescription')}}</label
                                                        >
                                                        <div class="col-md-9">                                                
                                                            <!-- Editor -->
                                                            <!-- <ckeditor v-model="LongDescription" :editor="editor"></ckeditor> -->
                                                            <textarea v-model="LongDescription" rows="10" class="w-100"></textarea>
                                                        </div>
                                                    </div>
                                                    <div class="form-group row mb-3">
                                                        <label class="col-md-3 col-form-label" for="IsHome"
                                                            >{{$t('user.HomePageIsTrue')}}</label
                                                        >
                                                        <div class="col-md-9">
                                                            <b-form-checkbox v-model="IsHome" name="IsHome" switch >
                                                        </b-form-checkbox>
                                                        </div>
                                                    </div>
                                                    <div class="form-group row mb-3">
                                                        <label class="col-md-3 col-form-label" for="OrderBy"
                                                            >{{$t('user.OrderNo')}}</label
                                                        >
                                                        <div class="col-md-9">
                                                            <input
                                                            type="number"
                                                            name="OrderNo"
                                                            class="form-control"
                                                            v-model="OrderBy"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div class="form-group row mb-3">
                                                        <label class="col-md-3 col-form-label" for="HomePageOrderBy"
                                                            >{{$t('user.homePageOrderBy')}}</label
                                                        >
                                                        <div class="col-md-9">
                                                            <input
                                                            type="number"
                                                            name="HomePageOrderBy"
                                                            class="form-control"
                                                            v-model="HomePageOrderBy"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div class="form-group row mb-3">
                                                        <label class="col-md-3 col-form-label" for="isActive"
                                                            >{{$t('user.Active')}}</label
                                                        >
                                                        <div class="col-md-9">
                                                            <b-form-checkbox v-model="isActive" name="isActive" switch >
                                                            </b-form-checkbox>
                                                        </div>
                                                    </div>
                                                    <div class="form-group row mb-3">
                                                        <label class="col-md-3 col-form-label" for="IsFavoriteFlag"
                                                            >{{$t('user.IsFavoriteFlag')}}</label
                                                        >
                                                        <div class="col-md-9">
                                                            <b-form-checkbox v-model="isFavoriteFlag" name="check-button" switch>
                                                        </b-form-checkbox>
                                                        </div>
                                                    </div>
                                                    <div class="form-group row mb-3">
                                                        <label class="col-md-3 col-form-label" for="isNewFlag"
                                                            >{{$t('user.isNewFlag')}}</label
                                                        >
                                                        <div class="col-md-9">
                                                            <b-form-checkbox v-model="isNewFlag" name="check-button" switch>
                                                        </b-form-checkbox>
                                                        </div>
                                                    </div>
                                                    <div class="mt-2">
                                                        <button type="submit" class="btn btn-primary me-1" @click="getAddCategory">
                                                        {{$t('user.SaveChanges')}}
                                                        </button>
                                                    </div>
                                                </div>                                    
                                                <!-- end col -->
                                            </div>
                                            <!-- end row -->
                                        </tab-content>
                                    </form-wizard>
                                </div>
                                <!-- end card-body -->
                            </div>
                        </div>            
                    </div>
                    <!-- end card -->
                </div>
                </div>
                <!-- end row -->
        </Loader>    
  </Layout>
</template>



